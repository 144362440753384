<template>
  <v-row>
    <v-col md="12" class="mt-5">

      <v-row align="center">
        <v-col>
          <div class="d-flex align-center">
            <h1 class="font15pt font-weight-bold mr-2">Drivers</h1>
            <tooltipComponent :tooltipText="tooltip" />
          </div>
        </v-col>
        <v-col>
          <v-tabs v-model="tab" right>
            <v-tab>Social Networks</v-tab>
            <v-tab>Newspapers</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-col>

    <v-col md="12 table--general">
    <template v-for="(network, key) in [socialNetworks, newspapers]">
      <v-data-table v-if="tab === key" :headers="network.headers" :items="network.drivers" :hide-default-header="false" :hide-default-footer="true"
        :light="true">
        <template v-slot:[`item.type`]="{ item }" class="text-center">
          <v-icon small class="mr-1" :color="item.color">
            {{ item.icon }}
          </v-icon>
        </template>
        <template v-slot:[`item.sentiment`]="{ item }">
          <v-icon v-if="item.sentiment == 'neu'" small color="#828a96">
            far fa-hand-point-right
          </v-icon>
          <v-icon v-if="item.sentiment == 'pos'" small color="#9fd895">
            far fa-thumbs-up
          </v-icon>
          <v-icon v-if="item.sentiment == 'neg'" small color="#ff7e7e">
            far fa-thumbs-down
          </v-icon>
        </template>
      </v-data-table>
    </template>
    </v-col>
  </v-row>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";

export default {
  components: { tooltipComponent },
  props: {
    drivers: Array,
  },
  data() {
    return {
      tooltip:
        "Element added in order to monitor their behavior in social networks",
      socialNetworks: {
        headers: [
          { text: "", value: "name" },
          {
            text: "Type",
            value: "type",
            align: "center",
            class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
            width: "100",
          },
          {
            text: "Sentiment",
            value: "sentiment",
            align: "center",
            class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
            width: "150",
          },
          {
            text: "Interactions",
            value: "interactions",
            align: "center",
            class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
            width: "150",
          },
        ],
        drivers: []
      },
      newspapers: {
        headers: [
          { text: "", value: "name" },
          {
            text: "Type",
            value: "type",
            align: "center",
            class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
            width: "100",
          },
          {
            text: "Sentiment",
            value: "sentiment",
            align: "center",
            class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
            width: "150",
          },
        ],
        drivers: []
      },
      tab: 0
    };
  },
  created() {

  },
  methods: {
    getNetworkIcon(network) {
      let color = "", 
          icon = "";

      switch (network) {
        case "tw":
          color = '#1da1f2'
          icon = 'fab fa-twitter'
          break;
        case "fb":
          color = '#598ec2'
          icon = 'fab fa-facebook'
          break;
        case "ig":
          color = '#ff69a1'
          icon = 'fab fa-instagram'
          break;
        case "lk":
          color = '#0a66c2'
          icon = 'fab fa-linkedin'
          break;
        default:
          color = '#444444'
          icon = 'fa-solid fa-newspaper'
            
      }

      return {
        color,
        icon
      }
    },
    getDrivers(networks) {
      if(!networks) return;
      const driversList = [];
        networks.map((i) => {
          const maxSentiment = Math.max.apply(
            Math,
            i["agg_terms_sample_sentiment.keyword"].buckets.map(function (o) {
              return o.doc_count;
            })
          );

          const maxSentimentItem = i[
            "agg_terms_sample_sentiment.keyword"
          ].buckets.find((x) => maxSentiment === x.doc_count);

          const { color, icon } = this.getNetworkIcon(i.agg_terms_source_snetwork_id.buckets[0].key)
          
          driversList.push({
            name: i["agg_terms_sample_post_author.keyword"].buckets[0] ? i["agg_terms_sample_post_author.keyword"].buckets[0].key || i.key : i.key,
            icon,
            color,
            sentiment: maxSentimentItem.key,
            interactions: i.agg_sum_sample_interactions.value,
          });
        });

        const resultMap = new Map();

        for (const item of driversList) {
          const key = `${item.name}-${item.sentiment}`;
          if (resultMap.has(key)) {
            const existingItem = resultMap.get(key);
            existingItem.interactions += item.interactions;
          } else {
            resultMap.set(key, { ...item });
          }
        }

      return Array.from(resultMap.values());
    }
  },
  watch: {
    drivers: {
      immediate: true,
      deep: true,
      handler() {
        this.socialNetworks = {...this.socialNetworks, drivers: this.getDrivers(this.drivers.socialNetworks)};
        this.newspapers = {...this.newspapers, drivers: this.getDrivers(this.drivers.newspapers)};

      },
    },
  },
};
</script>
