<template>
  <v-main>
    <v-row>
      <v-col md="6">
        <v-row align-items="center">
          <v-col>
            <div class="d-flex align-center mt-5">
              <h1 class="font15pt font-weight-bold mr-2">Male Sentiment</h1>
              <tooltipComponent :tooltipText="tooltip" />
            </div>
            <div class="mb-5">
              <span class="d-block font10pt leyendColor">At all Social Networks</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <apexchart
              height="350"
              :options="chartOptions"
              :series="male.series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="6">
        <v-row align-items="center">
          <v-col>
            <div class="d-flex align-center mt-5">
              <h1 class="font15pt font-weight-bold mr-2">Female Sentiment</h1>
              <tooltipComponent :tooltipText="tooltip" />
            </div>
            <div class="mb-5">
              <span class="d-block font10pt leyendColor">At all Social Networks</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <apexchart
              height="350"
              :options="chartOptions"
              :series="female.series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";
export default {
  name: "MaleFemaleSentiment",
  components: { tooltipComponent },
  props: {
    histograms: Array,
  },
  data() {
    return {
      tooltip: "Sentiment by gender",
      male: {
        found: 6000,
        sentiment: "neutral",
        emotions: "glad",
        series: [],
      },
      female: {
        found: 5000,
        sentiment: "neg",
        emotions: "angry",
        series: [],
      },
      chartOptions: {
        chart: {
          type: "bar",
          height: 200,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#ff7e7e", "#828a96", "#9fd895"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: "30%",
          },
        },
        xaxis: {
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: false,
          },
          tickPlacement: 'on',
          categories: [],
        },
        tooltip: {},
        yaxis: {
          tickAmount: 9,
          showAlways: false,
          title: {
            text: "Samples",
            fontWeight: 300,
          },
        },
        fill: {
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
        },
      },
    };
  },
  created() {},
  watch: {
    histograms: {
      immediate: true,
      deep: true,
      handler() {
        const maleList = [
          {
            name: "Negative",
            data: [],
          },
          {
            name: "Neutral",
            data: [],
          },
          {
            name: "Positive",
            data: [],
          },
        ];

        const femaleList = [
          {
            name: "Negative",
            data: [],
          },
          {
            name: "Neutral",
            data: [],
          },
          {
            name: "Positive",
            data: [],
          },
        ];

        const dateList = [];

        this.histograms.map((item) => {
          const genders = item["agg_terms_author_gender.keyword"].buckets;

          genders.map((gender) => {
            switch (gender.key) {
              case "male":
                gender["agg_terms_sample_sentiment.keyword"].buckets.map(
                  (sentimentItem) => {
                    switch (sentimentItem.key) {
                      case "neg":
                        maleList[0].data.push(sentimentItem.doc_count);
                        break;
                      case "neu":
                        maleList[1].data.push(sentimentItem.doc_count);
                        break;
                      case "pos":
                        maleList[2].data.push(sentimentItem.doc_count);
                        break;
                    }
                  }
                );

                break;
              case "female":
                gender["agg_terms_sample_sentiment.keyword"].buckets.map(
                  (sentimentItem) => {
                    switch (sentimentItem.key) {
                      case "neg":
                        femaleList[0].data.push(sentimentItem.doc_count);
                        break;
                      case "neu":
                        femaleList[1].data.push(sentimentItem.doc_count);
                        break;
                      case "pos":
                        femaleList[2].data.push(sentimentItem.doc_count);
                        break;
                    }
                  }
                );
                break;
            }
          });

          dateList.push(util.convertUnixToDate(item.key));
        });
        this.male.series = maleList;
        this.female.series = femaleList;

        this.chartOptions.xaxis.categories = dateList;

        this.series = [
          {
            name: "Masculino",
            data: maleList,
          },
          {
            name: "Femenino",
            data: femaleList,
          },
        ];
      },
    },
  },
};
</script>

<style>
.box-gender {
  width: 107px;
  height: 65px;
  border-radius: 6px;
}
</style>
