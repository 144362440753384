<template>
  <v-main>
    <v-row>
      <v-col class="d-flex align-center mb-5">
        <h1 class="font15pt font-weight-bold mr-2">
          Influencer & Accounts Ranking
        </h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="table--general">
          <v-data-table :headers="headers" :items="itemsTable" :hide-default-header="false" :hide-default-footer="true"
            :items-per-page="15" :light="true" :calculate-widths="true" class="">
            <template v-slot:[`item.accounts`]="{ item }" class="text-center">
              <div
                style="line-height: 1em; max-width: 250px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                class="d-flex align-center">
                <v-avatar size="25">
                  <img alt="user" :src="item.avatar"
                    @error="$event.target.src = 'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'" />
                </v-avatar>
                <span style="padding-left:10px; margin: 0;" v-if="item.network == 'tw'">@{{ item.accounts }}</span>
                <span style="padding-left:10px; margin: 0;" v-if="item.network != 'tw'">{{ item.accounts }}</span>
              </div>
            </template>
            <template v-slot:[`item.network`]="{ item }" class="text-center">
              <v-icon v-if="item.network.includes('fb')" small class="mr-1" color="#598ec2">
                fab fa-facebook
              </v-icon>
              <v-icon v-if="item.network.includes('tw')" small class="mr-1" color="#1da1f2">
                fab fa-twitter
              </v-icon>
              <v-icon v-if="item.network.includes('ig')" small class="mr-1" color="#ff69a1">
                fab fa-instagram
              </v-icon>
              <v-icon v-if="item.network.includes('lk')" small class="mr-1" color="#0a66c2">
                fab fa-linkedin
              </v-icon>
              <v-icon v-if="item.network.includes('nws')" small class="mr-1" color="#444444">
                fa-solid fa-newspaper
              </v-icon>
            </template>
            <template v-slot:[`item.sentiment`]="{ item }">
              <v-icon v-if="item.sentiment == 'neu'" small color="#828a96">
                far fa-hand-point-right
              </v-icon>
              <v-icon v-if="item.sentiment == 'pos'" small color="#9fd895">
                far fa-thumbs-up
              </v-icon>
              <v-icon v-if="item.sentiment == 'neg'" small color="#ff7e7e">
                far fa-thumbs-down
              </v-icon>
            </template>
          </v-data-table>

          <div class="d-flex bgColorBase">
            <div class="col pa-1 d-flex">
              <div class="col-2"></div>
              <div class="col d-flex justify-start align-center">
                <span class="parColor font12pt font-weight-bold">Total</span>
              </div>
            </div>
            <div class="col d-flex justify-end align-center">
              <div class="tcom text-center">
                <span class="parColor font12pt font-weight-bold">
                  {{ totalCommunity | withComma }}
                </span>
              </div>
              <div class="text-center tint">
                <span class="parColor font12pt font-weight-bold">
                  {{ totalInteractions | withComma }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
export default {
  name: "InfluencersRanking",
  components: { tooltipComponent },
  props: ["dataInfluncers"],
  data() {
    return {
      totalCommunity: 0,
      totalInteractions: 0,
      tooltip: "Detail of the top 15 that generate more interaction",
      headers: [
        {
          text: "Score",
          align: "center",
          value: "score",
          sortable: false,
          class: ["font-weight-bold", "titleColor", "font13pt"],
          width: "150",
        },
        {
          text: "Influencer & Accounts",
          align: "start",
          value: "accounts",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          width: "200"
        },
        {
          text: "Social Network",
          align: "center",
          value: "network",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
        {
          text: "Sentiment",
          align: "center",
          value: "sentiment",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
        {
          text: "Community",
          align: "center",
          value: "community",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
        {
          text: "Interactions",
          align: "center",
          value: "interactions",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
      ],
      itemsTable: [],
    };
  },
  mounted() {
    //this.setaDataTable();
  },
  methods: {
    setaDataTable() {
      let totalCommunity = 0;
      let totalInteractions = 0;
      this.dataInfluncers.map((x) => {
        totalCommunity += x.values.comunity;
        totalInteractions += x.values.interactions;
        let item = {
          accounts: x.userName,
          avatar: x.userAvatar,
          network: x.socialNetwork,
          score: x.values.score,
          sentiment: x.values.sentiment,
          community: x.values.comunity,
          interactions: x.values.interactions,
        };
        this.itemsTable.push(item);
      });
      this.totalCommunity = totalCommunity;
      this.totalInteractions = totalInteractions;
    },
  },
  watch: {
    dataInfluncers: {
      immediate: true,
      handler() {
        this.setaDataTable();
      },
    },
  },
};
</script>

<style>
.w180 {
  width: 170px;
}

.tint {
  padding-right: 10.5%;
  padding-left: 24.5%;
}

.table--general thead tr th:nth-child(2) {
  text-align: center !important;
}

/* .table--general tbody tr:nth-child(2n + 1)td{
        background-color: rosybrown;
    } */
.table--general td {
  line-height: 48px;
}
</style>
