<template>
  <v-main>
    <v-row>
      <v-col md="12" style="max-height:70px">
        <div class="d-flex align-center mt-5">
          <h1 class="font15pt font-weight-bold mr-2">
            Gender Sentiment
          </h1>
          <tooltipComponent :tooltipText="tooltip" />
        </div>
        <div class="mb-5">
          <span class="d-block font10pt leyendColor"
            >At all Social Networks</span
          >
        </div>
      </v-col>
      <v-col class="d-flex" style="margin-bottom:15px">
        <div class="col">
          <div class="box-gender bgMale d-flex aling-center justify-center">
            <v-icon size="40" color="#fff">fas fa-mars</v-icon>
          </div>
        </div>
        <div class="col d-flex flex-column text-center justify-center">
          <span class="font12pt parColor font-weight-bold mb-1">Found</span>
          <span class="font12pt parColor">{{ male.found }}</span>
        </div>
        <div class="col d-flex flex-column text-center justify-center">
          <span class="font12pt parColor font-weight-bold">Sentiment</span>
          <v-icon v-if="male.sentiment == 'pos'" color="#9fd895" size="18"
            >far fa-thumbs-up</v-icon
          >
          <v-icon v-if="male.sentiment == 'neg'" color="#ff7e7e" size="18"
            >far fa-thumbs-down</v-icon
          >
          <v-icon v-if="male.sentiment == 'neu'" color="#828a96" size="22"
            >fas fa-ellipsis-h</v-icon
          >
        </div>
        <div class="col d-flex flex-column text-center justify-center">
          <span class="font12pt parColor font-weight-bold mb-1">Emotions</span>
          <span class="font12pt parColor text-capitalize">{{
            male.emotions
          }}</span>
        </div>
      </v-col>
      <v-col class="d-flex" style="margin-bottom:15px">
        <div class="col">
          <div class="box-gender bgFemale d-flex aling-center justify-center">
            <v-icon size="40" color="#fff">fas fa-venus</v-icon>
          </div>
        </div>
        <div class="col d-flex flex-column text-center justify-center">
          <span class="font12pt parColor font-weight-bold mb-1">Found</span>
          <span class="font12pt parColor">{{ female.found }}</span>
        </div>
        <div class="col d-flex flex-column text-center justify-center">
          <span class="font12pt parColor font-weight-bold">Sentiment</span>
          <v-icon v-if="female.sentiment == 'pos'" color="#9fd895" size="18"
            >far fa-thumbs-up</v-icon
          >
          <v-icon v-if="female.sentiment == 'neg'" color="#ff7e7e" size="18"
            >far fa-thumbs-down</v-icon
          >
          <v-icon v-if="female.sentiment == 'neu'" color="#828a96" size="22"
            >fas fa-ellipsis-h</v-icon
          >
        </div>
        <div class="col d-flex flex-column text-center justify-center">
          <span class="font12pt parColor font-weight-bold mb-1">Emotions</span>
          <span class="font12pt parColor text-capitalize">{{
            female.emotions
          }}</span>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";

export default {
  name: "GenderSentiment",
  components: { tooltipComponent },
  props: {
    genders: Object,
    emotions: Object,
  },
  data() {
    return {
      tooltip: "Gender by sentiment and emotions.",
      male: {
        found: 0,
        sentiment: "neu",
        // TODO: EMOTIONS
        emotions: "unknown",
      },
      female: {
        found: 0,
        sentiment: "neu",
        // TODO: EMOTIONS
        emotions: "unknown",
      },
    };
  },
  created() {
    // this.male.found = this.genders.m.count;
    // this.female.found = this.genders.f.count;
    // const currentGenderFemaleList = [];
    // Object.entries(
    //   this.genders.f.details["agg_terms_sample_sentiment.keyword"]
    // ).map((item) => {
    //   currentGenderFemaleList.push({
    //     key: item[0],
    //     count: item[1],
    //   });
    // });
    // const currentGenderMaleList = [];
    // Object.entries(
    //   this.genders.m.details["agg_terms_sample_sentiment.keyword"]
    // ).map((item) => {
    //   currentGenderMaleList.push({
    //     key: item[0],
    //     count: item[1],
    //   });
    // });
    // const maxFemale = util.maxFromObjectArray(currentGenderFemaleList, "count"); // (array, nombre de variable)
    // const maxMale = util.maxFromObjectArray(currentGenderFemaleList, "count"); // (array, nombre de variable)
    // this.female.sentiment = maxFemale.key;
    // this.male.sentiment = maxMale.key;
    // EMOTIONS
  },
  methods: {
    calculateEmotions() {
      const count_emotions = {
        male: {
          hap: 0,
          ang: 0,
          neu: 0,
          sad: 0,
          fer: 0,
          srp: 0,
          dsg: 0,
        },
        female: {
          hap: 0,
          ang: 0,
          neu: 0,
          sad: 0,
          fer: 0,
          srp: 0,
          dsg: 0,
        },
      };
      const emotions = Object.keys(count_emotions.male);

      let m_emotion_max_text = "None";
      let f_emotion_max_text = "None";
      for (const genre of ["male", "female"]) {
        for (const emotion of emotions) {
          const total_emotion =
            this.emotions[emotion]?.details["agg_terms_author_gender.keyword"][
              genre
            ] || 0;
          count_emotions[genre][emotion] = total_emotion;
        }
        const emotion_max_genre = Math.max(
          ...Object.values(count_emotions[genre])
        );

        if (emotion_max_genre != 0) {
          for (const emotion of emotions) {
            if (count_emotions[genre][emotion] == emotion_max_genre) {
              genre == "male"
                ? (m_emotion_max_text = this.getCompleteEmotion(emotion))
                : (f_emotion_max_text = this.getCompleteEmotion(emotion));
            }
          }
        }
      }
      this.male.emotions = m_emotion_max_text;
      this.female.emotions = f_emotion_max_text;
    },
    capitalizeFirstLetter(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
    getCompleteEmotion(emo){
      switch(emo){
        case 'hap':
          return 'Happy';
        case 'ang':
          return 'Angry';
        case 'neu':
          return 'Neutral';
        case 'sad':
          return 'Sad';
        case 'fer':
          return 'Fearful';
        case 'srp':
          return 'Surprised';
        case 'dsg':
          return 'Disgusted';
      }
    }
  },
  watch: {
    genders: {
      immediate: true,
      deep: true,
      handler() {
        this.male.found = this.genders.male?.count || 0;
        this.female.found = this.genders.female?.count || 0;

        const currentGenderFemaleList = [];

        if(this.genders.female) {
          Object.entries(
            this.genders.female.details["agg_terms_sample_sentiment.keyword"]
          ).map((item) => {
            currentGenderFemaleList.push({
              key: item[0],
              count: item[1],
            });
          });
        }

        const currentGenderMaleList = [];
        if(this.genders.male) {
          Object.entries(
            this.genders.male.details["agg_terms_sample_sentiment.keyword"]
          ).map((item) => {
            currentGenderMaleList.push({
              key: item[0],
              count: item[1],
            });
          });
        } 

        const maxFemale = util.maxFromObjectArray(
          currentGenderFemaleList,
          "count"
        ); // (array, nombre de variable)
        
        const maxMale = util.maxFromObjectArray(
          currentGenderMaleList,
          "count"
        ); // (array, nombre de variable)

        this.female.sentiment = maxFemale.key || 'neu';
        this.male.sentiment = maxMale.key || 'neu';
      },
    },
    emotions: {
      immediate: true,
      deep: true,
      handler() {
        this.calculateEmotions();
      },
    },
  },
};
</script>

<style>
.box-gender {
  width: 107px;
  height: 65px;
  border-radius: 6px;
}
</style>
