<template>
  <v-main>
    <v-row class="d-flex flex-column mb-5">
      <div class="d-flex align-center mt-5">
        <h1 class="font15pt font-weight-bold mr-2">All Popular Hashtags</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </div>
      <div class="mb-5">
        <span class="d-block font10pt leyendColor">On Facebook, Twitter, Instagram & Linkedin</span>
      </div>
    </v-row>
    <timeline ref="timeline" :items="items" :options="options"></timeline>
  </v-main>
</template>


<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";

export default {
  name: "AllPopularHashtags",
  components: { tooltipComponent },
  props: {
    histograms: Array,
  },
  data() {
    return {
      tooltip: "Trends generated in the samples by social network",
      options: {
        height: 500,
        // rollingMode: {
        //     follow: false
        // }
      },
      groups: [],
    };
  },
  created() {},
  watch: {
    histograms: {
      immediate: true,
      deep: true,
      handler() {
        let data = {};
        let count = 0;
        this.histograms.map((item) => {
          item["agg_terms_sample_hashtags.keyword"].buckets.map(
            (hashtagItem, index) => {
              if (!data.hasOwnProperty(hashtagItem.key)) {
                data[hashtagItem.key] = {
                  id: count + index,
                  start: util.convertTimestampToDate(item.key),
                  // end: '2022-02-03',
                  content: `#${hashtagItem.key}`,
                };
              } else {
                data[hashtagItem.key]["end"] = util.convertTimestampToDate(
                  item.key
                );
              }

              count += index;
            }
          );
          count++;
        });

        const hashtagsList = Object.keys(data).map((i) => {
          return data[i];
        });
        this.items = hashtagsList;
      },
    },
  },
};
</script>
