<template>
  <v-container class="pa-0">
    <v-row>
      <v-col class="d-flex align-center mb-5">
        <h1 class="font15pt font-weight-bold mr-2">Sentiment share</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
      <v-col>
          <v-tabs v-model="tab" right>
            <v-tab>Social Networks</v-tab>
            <v-tab>Newspapers</v-tab>
          </v-tabs>
        </v-col>
    </v-row>
    <v-row class="sentimentshared__content pl-4 pr-4" v-for="(network, indexNetwork) in [socialNetworks.data, newspapers.data]" :key="indexNetwork"  v-if="tab === indexNetwork">
      <v-col
        md="4"
        v-for="(item, index) in network"
        :key="index"
        class="sentimentshared__content__item"
      >
        <v-card class="pa-4" flat>
          <h1
            class="
              mb-4
              font-weight-light
              sentimentshared__content__item__title
              titleColor
              font15pt
              text-center text-capitalize
            "
          >
            {{ item.title }}
          </h1>
          <div
            class="sentimentshared__content__item__value d-flex align-center"
          >
            <div
              class="
                col
                pa-0
                sentiment__box__item__value__perc
                bgColorBase
                flex-grow-1 flex-shrink-0
              "
            >
              <div
                :class="`bgColor${item.title}`"
                class="
                  sentiment__box__item__value__perc--color
                  position-absolute
                "
                :style="{ width: item.value + '%' }"
              ></div>
            </div>
            <span
              class="ml-3 parColor font-weight-light flex-grow-0 flex-shrink-0"
              >{{ item.value }}%</span
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapGetters } from 'vuex'
import tooltipComponent from "@/components/base/tooltip.vue";
export default {
  name: "SentimentShared",
  components: { tooltipComponent },
  // computed:{
  //     ...mapGetters('moduleOverview',{
  //         sentiment: 'getSentimentShared'
  //     })
  // },
  props: {
    sentiments: Object,
  },
  data() {
    return {
      tab: 0,
      tooltip: "Sentiment of the total sample according to the selected dates.",
      sentimentsList: [],
      socialNetworks: {
        data: []
      },
      newspapers: {
        data: []
      }
    };
  },
  created() {
  },
  methods: {
    getSentimentShared(network) {

      const totalCountSentiment = Object.keys(network).reduce((a, b) => network[b] + a, 0)

      const data = [
        {
          title: 'positive',
          value: network.pos ? Math.round((network.pos * 100) / totalCountSentiment) : 0,
        },
        {
          title: 'neutral',
          value: network.neu ? Math.round((network.neu * 100) / totalCountSentiment) : 0,
        },
        {
          title: 'negative',
          value: network.neg ? Math.round((network.neg * 100) / totalCountSentiment) : 0,
        }
      ];

      return {
        data
      }
    }
  },
  watch: {
    sentiments: {
      immediate: true,
      deep: true,
      handler() {

        this.socialNetworks = this.getSentimentShared(this.sentiments.socialNetworksData.sentiment)
        this.newspapers = this.getSentimentShared(this.sentiments.newspapersData.sentiment)
        
      },
    },
  },
};
</script>

<style></style>
