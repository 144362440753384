<template>
  <v-main>
    <v-row align-items="center">
      <v-col md="6">
        <div class="d-flex align-center mt-5">
          <h1 class="font15pt font-weight-bold mr-2">
            Gender Daily Progression
          </h1>
          <tooltipComponent :tooltipText="tooltip" />
        </div>
        <div class="mb-5">
          <span class="d-block font10pt leyendColor"
            >At all Social Networks</span
          >
        </div>
      </v-col>
      <v-col md="6" class="d-flex justify-end align-center">
        <div>
          <input
            class="checkbox"
            type="radio"
            id="check-all"
            v-model="sentimentSelected"
            value="all"
          />
  
          <label for="check-all" class="font12pt parColor">All</label>
        </div>
        <div>
          <input
            class="checkbox"
            type="radio"
            id="check-positive"
            v-model="sentimentSelected"
            value="pos"
          />

          <label for="check-positive" class="font12pt parColor">Positive</label>
        </div>
        <div>
          <input
            class="checkbox"
            type="radio"
            id="check-negative"
            v-model="sentimentSelected"
            value="neg"
          />
       
          <label for="check-negative" class="font12pt parColor">Negative</label>
        </div>
        <div>
          <input
            class="checkbox"
            type="radio"
            id="check-neutral"
            v-model="sentimentSelected"
            value="neu"
          />
   
          <label for="check-neutral" class="font12pt parColor">Neutral</label>
        </div>
        <!-- All <input type="checkbox" name="" id=""> Positive <input type="checkbox" name="" id=""> Negative <input type="checkbox" name="" id=""> Neutral <input type="checkbox" name="" id=""> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col class="graphgenderdaily">
        <apexchart
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";
export default {
  name: "GenderDailyProgression",
  components: { tooltipComponent },
  props: {
    histograms: Array,
  },
  data() {
    return {
      tooltip: "Gender by sentiment and emotions.",
      male: {
        found: 6000,
        sentiment: "neutral",
        emotions: "glad",
      },
      female: {
        found: 5000,
        sentiment: "neg",
        emotions: "angry",
      },
      sentimentSelected: "all",
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
        colors: ["#00b1f2", "#ff9a9a"],
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          showForNullSeries: false,
          labels: {
            show: true,
            formatter: function (val) {
              if (val) {
                return Math.round(val);
              }
            },
          },
          min: 0,
          max: 10,
          tickAmount: 1,
          opposite: false,
          showAlways: false,
          title: {
            text: "Samples",
            fontWeight: 300,
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 5,
          },
        },
        legend: {
          horizontalAlign: "right",
          markers: {
            radius: 0,
          },
        },
        // tooltip: {
        //     x: {
        //         format: 'dd/MM/yyyy'
        //     },
        // },
      },
    };
  },
  created() {},
  methods: {
    changeSentiment(e) {},
  },
  watch: {
    histograms: {
      immediate: true,
      deep: true,
      handler() {
        const maleList = [];
        const femaleList = [];
        const dateList = [];
        // const maleList = []
        this.histograms.map((item) => {
          const genders = item["agg_terms_author_gender.keyword"].buckets;

          genders.map((gender) => {
            switch (gender.key) {
              case "male":
                maleList.push(gender.key === "male" ? gender.doc_count : 0);
                break;
              case "female":
                femaleList.push(gender.key === "female" ? gender.doc_count : 0);
                break;
            }
          });

          dateList.push(util.convertUnixToDate(item.key));
        });
        this.chartOptions.xaxis.categories = dateList;
        this.series = [
          {
            name: "Masculino",
            data: maleList,
          },
          {
            name: "Femenino",
            data: femaleList,
          },
        ];
        const seriesArray = [...new Set([...maleList, ...femaleList])];
        this.chartOptions.yaxis.min = 0;
        this.chartOptions.yaxis.max = Math.max(...seriesArray);
        if (seriesArray.length > 1) {
          delete this.chartOptions.yaxis.tickAmount;
        }
      },
    },
    sentimentSelected: {
      handler() {
        const maleList = [];
        const femaleList = [];
        const dateList = [];
        // const maleList = []
        this.histograms.map((item) => {
          const genders = item["agg_terms_author_gender.keyword"].buckets;

          genders.map((gender) => {
            const sentimentCount = gender[
              "agg_terms_sample_sentiment.keyword"
            ].buckets.find((i) => i.key === this.sentimentSelected)
              ? gender["agg_terms_sample_sentiment.keyword"].buckets.find(
                  (i) => i.key === this.sentimentSelected
                ).doc_count
              : 0;
            let currentCount = 0;
            switch (gender.key) {
              case "male":
                currentCount =
                  this.sentimentSelected === "all"
                    ? gender.key === "male"
                      ? Math.round(gender.doc_count)
                      : 0
                    : sentimentCount;
                maleList.push(currentCount);
                break;
              case "female":
                currentCount =
                  this.sentimentSelected === "all"
                    ? gender.key === "female"
                      ? Math.round(gender.doc_count)
                      : 0
                    : sentimentCount;
                femaleList.push(currentCount);
                break;
            }
          });

          dateList.push(util.convertTimestampToDate(item.key));
        });

        this.chartOptions.xaxis.categories = dateList;

        this.series = [
          {
            name: "Masculino",
            data: maleList,
          },
          {
            name: "Femenino",
            data: femaleList,
          },
        ];
        const seriesArray = [...new Set([...maleList, ...femaleList])];
        this.chartOptions.yaxis.min = 0;
        this.chartOptions.yaxis.max = Math.max(...seriesArray);
        if (seriesArray.length > 1) {
          delete this.chartOptions.yaxis.tickAmount;
        }
      },
    },
  },
};
</script>

<style>
.box-gender {
  width: 107px;
  height: 65px;
  border-radius: 6px;
}
.graphgenderdaily .apexcharts-legend {
  padding-top: 20px !important;
}
</style>
