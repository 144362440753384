<template>
  <v-main class="v-main__influencers">
    <v-row>
      <v-col class="d-flex align-center mb-5">
        <h1 class="font15pt font-weight-bold mr-2">
          Top 15 Influencers & Accounts
        </h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="
          influencers-account-container
          pa-5
          pt-0
          pb-0
          d-flex
          justify-space-between
          flex-wrap
        ">
        <div v-for="(item, index) in data" :key="index"
          class="influencers-account-container__item position-relative mb-4">
          <div class="content--ribbon position-absolute" :class="{
            ribbonpositive: item.values.sentiment == 'pos',
            ribbonnegative: item.values.sentiment == 'neg',
            ribbonneutral: item.values.sentiment == 'neu',
          }"></div>
          <div class="influencers-account-container__item--score pa-3 pb-0">
            <span class="font11pt font-weight-light parColor">Influencer Score: <span>{{ item.values.score
            }}</span>%</span>
          </div>
          <div class="
              influencers-account-container__item--userinfo
              d-flex
              align-center
              pa-3
            ">
            <v-avatar size="50">
              <img alt="user" :src="item.userAvatar"
                @error="$event.target.src = 'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'" />
            </v-avatar>
            <div class="d-flex flex-column pl-2">
              <span class="
                  font-weight-bold
                  titleInfluencerColor
                  font18pt
                  text-truncate
                " style="max-width: 110px">
                <span v-if="item.socialNetwork == 'tw'">@</span>{{ item.userName }}
              </span>
              <span class="ColorMale d-flex font10pt">
                <v-icon v-if="item.socialNetwork == 'fb'" size="10" color="#1877f2">
                  fab fa-facebook
                </v-icon>
                <v-icon v-if="item.socialNetwork == 'tw'" size="10" color="#00b1f2">
                  fab fa-twitter
                </v-icon>
                <v-icon v-if="item.socialNetwork == 'ig'" size="10" color="#ff69a1">
                  fab fa-instagram
                </v-icon>
                <v-icon v-if="item.socialNetwork == 'lk'" size="10" color="#0a66c2">
                  fab fa-linkedin
                </v-icon>
                <!-- <v-icon
                  v-if="item.socialNetwork == 'nws'"
                  size="10"
                  color="#444444"
                >
                fa-solid fa-newspaper
                </v-icon> -->
                <div class="ml-1">
                  <span v-if="item.socialNetwork == 'tw'">@</span>
                  <span class="cursorPoint" @click="sendUrl(item, data)">{{
                    item.userName }}</span>
                </div>
              </span>
            </div>
          </div>
          <div class="influencers-account-container__item--values__box pa-3 pt-2">
            <!-- <div class="
                influencers-account-container__item--values__box-item
                d-flex
                mt-1
              ">
              <div class="col pa-0">
                <span class="parColor font12pt">Comunity:</span>
              </div>
              <div class="col pa-0">
                <span class="parColor font12pt">{{
                  item.values.communityAbbr
                }}</span>
              </div>
            </div> -->
            <div class="
                influencers-account-container__item--values__box-item
                d-flex
                mt-1
              ">
              <div class="col pa-0">
                <span class="parColor font12pt">Interactions:</span>
              </div>
              <div class="col pa-0">
                <span class="parColor font12pt">{{
                  item.values.interactions
                }}</span>
              </div>
            </div>
            <div class="
                influencers-account-container__item--values__box-item
                d-flex
                mt-1
              ">
              <div class="col pa-0">
                <span class="parColor font12pt">Sentiment:</span>
              </div>
              <div class="col pa-0">
                <span v-if="item.values.sentiment == 'pos'" class="font12pt positive">Positive</span>
                <span v-if="item.values.sentiment == 'neg'" class="font12pt negative">Negative</span>
                <span v-if="item.values.sentiment == 'neutral' || item.values.sentiment == 'neu'" class="neutral font12pt">Neutral</span>
              </div>
            </div>
          </div>
          <div>
            <v-dialog v-model="item.open" width="400">
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" class="
                    influencers-account-container__item--button
                    mt-2
                    col
                    pt-3
                    pb-3
                    bgColorBase
                    parColor
                  ">
                  View Post
                </button>
              </template>

              <div class="modalcontent elevation-2 pa-3 white">
                <div class="modalcontent__title pb-4 position-relative">
                  <span class="leyendColor font10pt"
                    v-if="item.socialNetwork == 'fb' || item.socialNetwork == 'ig' || item.socialNetwork == 'lk'">Top 3
                    post by interactions</span>
                  <span class="leyendColor font10pt" v-if="item.socialNetwork == 'tw'">Top 3 tweets by interactions</span>
                  <div class="close-modal-btn position-absolute" @click="item.open = false">
                    <v-icon color="#37afef"> fas fa-times-circle </v-icon>
                  </div>
                </div>
                <div class="modalcontent__content">
                  <div class="modalcontent__content__item" :class="{ heightAuto: item.modalcontent.length <= 1 }">
                    <div v-if="item.modalcontent.length === 0" class="pa-4 text-center">
                      <h3 class="text--disabled">NO DATA</h3>
                    </div>
                    <div v-if="item.modalcontent">
                      <div v-for="(data, index) in item.modalcontent" :key="index" class="mb-5 pb-3 border_bt">
                        <div class="modalcontent__content__user d-flex">
                          <v-avatar size="40">
                            <img alt="user" :src="data.userAvatar"
                              @error="$event.target.src = 'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'" />
                          </v-avatar>
                          <div class="
                              modalcontent__content__userinfo
                              d-flex
                              flex-column
                              justify-end
                              pl-2
                            ">
                            <div>
                              <span class="font12pt font-weight-bold">
                                {{ data.userName }}
                              </span>
                              <span class="ColorMale font10pt">
                                @{{ data.userName }}
                              </span>
                            </div>
                            <div class="leyendColor font10pt">
                              <span class="">
                                {{ data.postDate }}
                              </span>
                              -
                              <span class="">
                                {{ data.postTime }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="modalcontent__content__comments mt-2">
                          <div class="modalcontent__content__comments__text pt-2">
                            <span class="parColor font12pt">
                              {{ data.postcontent }}
                            </span>
                          </div>
                          <div v-if="data.postImage" class="modalcontent__content__comments__image mt-5">
                            <v-img :src="data.postImage"></v-img>
                          </div>
                          <div class="
                              modalcontent__content__comments__reactions
                              d-flex
                              mt-5
                              mb-2
                            ">
                            <div v-if="data.likeCount && item.socialNetwork == 'fb'
                              " class="
                                modalcontent__content__comments__reactions__item
                              ">
                              <v-icon size="10" color="#a2acc4">
                                fas fa-thumbs-up
                              </v-icon>
                              <span class="leyendColor font10pt pl-1">{{
                                data.likeCount
                              }}</span>
                            </div>
                            <div v-if="data.sharedCount" class="
                                modalcontent__content__comments__reactions__item
                                pl-2
                              ">
                              <v-icon size="10" color="#a2acc4">
                                fas fa-share
                              </v-icon>
                              <span class="leyendColor font10pt pl-1">{{
                                data.sharedCount
                              }}</span>
                            </div>
                            <div v-if="data.comentsCount" class="
                                modalcontent__content__comments__reactions__item
                                pl-2
                              ">
                              <v-icon size="10" color="#a2acc4">
                                fas fa-comments
                              </v-icon>
                              <span class="leyendColor font10pt pl-1">{{
                                data.comentsCount
                              }}</span>
                            </div>
                            <div v-if="data.retweetCount" class="
                                modalcontent__content__comments__reactions__item
                                pl-2
                              ">
                              <v-icon size="10" color="#a2acc4">
                                fas fa-retweet
                              </v-icon>
                              <span class="leyendColor font10pt pl-1">{{
                                data.retweetCount
                              }}</span>
                            </div>
                            <div v-if="data.likeCount && item.socialNetwork == 'tw'
                                " class="
                                modalcontent__content__comments__reactions__item
                                pl-2
                              ">
                              <v-icon size="10" color="#a2acc4">
                                fas fa-heart
                              </v-icon>
                              <span class="leyendColor font10pt pl-1">{{
                                data.likeCount
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-dialog>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
export default {
  name: "TopInfluencerAccounts",
  components: { tooltipComponent },
  props: { dataInfluncers: Array },
  data() {
    return {
      dialog: false,
      tooltip:
        "Top 15 people or accounts who have more interaction in their publications",
      data: [],
    };
  },
  created() {
  },
  mounted() { },
  methods: {
    sendUrl(data) {
      if (data.socialNetwork == "fb") {
        if (data.idfb.length) {
          const idfb = data.idfb[0]["agg_terms_sample_post_author_id.keyword"].buckets[0].key
          window.open(
            `https://www.facebook.com/${idfb}`,
            "_blank"
          );
        }
      }
      if (data.socialNetwork == 'ig') {
        window.open(`https://www.instagram.com/${data.userName}`, "_blank");
      }
      if (data.socialNetwork == 'tw') {
        window.open(`https://twitter.com/${data.userName}`, "_blank");
      }
      if (data.socialNetwork == 'lk') {
        //! We need a user type like user or profile
        // If person
        // window.open(`https://linkedin.com/in/${data.userName}`, "_blank");
        // If company
        window.open(`https://linkedin.com/company/${data.userName}`, "_blank");
      }
    },
  },
  watch: {
    dataInfluncers: {
      immediate: true,
      handler() {
        this.data = this.dataInfluncers.slice(0, 15);
      },
    },
  },
};
</script>
<style>
.influencers-account-container__item {
  width: 210px;
  border: 1px solid #c5d0e4;
  padding: 5px;
  min-height: 250px;
}

.cursorPoint {
  cursor: pointer;
}

.content--ribbon {
  top: 5px !important;
  right: 5px !important;
}

.heightAuto {
  height: auto !important;
  overflow: hidden !important;
}
</style>
